<template>
  <div>
    <div class="nbanner">
      <a :href="bannerPDF" download>
        {{ bannerEntitle }}
      </a>

      <div class="swiper-container swiper-container1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, index) in imageUrl" :key="index">
            <div class="li">
              <img :src="v.photo" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination pagination1"></div>
      </div>
    </div>
    <div class="tabs">
      <ul class="f-no-s-center tabsTop">
        <li>
          <router-link class="titleColor on" to="/esg" style="color: #396aff">
            战略与行动
          </router-link>
        </li>
        <li>
          <router-link class="titleColor" to="/report">
            报告与披露
          </router-link>
        </li>
        <li>
          <router-link class="titleColor" to="/statement">
            声明与制度
          </router-link>
        </li>
      </ul>
    </div>
    <div class="n-esg">
      <div class="esglist2">
        <div class="contentbox">
          <div class="ztitle">
            恒生ESG动态
            <img src="../assets/images/zimg/img12.png" alt="" />
          </div>
          <div class="ulbox f-no">
            <div
              v-for="(v, index) in trends_flag"
              :key="index"
              @click="tolink(v.id)"
              class="li"
            >
              <div class="img por ovh">
                <img :src="v.photo" alt="" />
              </div>
              <div class="titbox">
                <div class="tit3" v-html="v.title"></div>
                <div class="tit4" v-html="v.content"></div>
                <div class="time" v-html="v.timeline"></div>
              </div>
            </div>
            <div class="rightbox">
              <div
                v-for="(v, index) in trends_list"
                :key="index"
                @click="tolink(v.id)"
                class="li2"
              >
                <div class="tit4" v-html="v.title"></div>
                <div class="time" v-html="v.timeline"></div>
              </div>
            </div>
          </div>
          <router-link to="/esglist" class="morebtn">MORE+</router-link>
        </div>
      </div>
      <div class="esglist3">
        <div class="warpbox">
          <div class="contentbox">
            <div class="ztitle">
              恒生可持续发展战略
              <img src="../assets/images/zimg/img7-3.png" alt="" />
            </div>
            <div class="box f-no-b-center">
              <div class="text" v-html="strategy.content"></div>
              <div class="img">
                <img :src="strategy.photo" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="contentbox2 f-no">
          <div
            :class="active == index ? 'li por ovh active' : 'li por ovh'"
            @mouseover="active = index"
            v-for="(v, index) in strategy_photo"
            :key="index"
          >
            <img :src="v.photo" alt="" />
            <div class="txtbox">
              <div class="tit2" v-html="v.title"></div>
              <div class="p3" v-html="v.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="esglist4">
        <div class="contentbox">
          <div class="ztitle">
            {{ manage_list.title }}
            <img src="../assets/images/zimg/img7-4.png" alt="" />
          </div>
          <div class="img f-no-c-center">
            <img :src="manage_list.photo" alt="" />
          </div>
        </div>
      </div>
      <div class="esglist5">
        <div class="contentbox">
          <div class="ztitle">
            ESG评级
            <img src="../assets/images/zimg/img7-5.png" alt="" />
          </div>
          <div class="ul f-no-b-center swiper-container2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(v, index) in rate_list"
                :key="index"
              >
                <div class="li">
                  <img class="bg" src="../assets/images/zimg/img8.png" alt="" />
                  <div class="text f-no-col-c-center">
                    <div class="icon f-no-c-center">
                      <img :src="v.photo" alt="" />
                    </div>
                    <div class="p2 f-no-c-center" v-html="v.title"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-pagination pagination2"></div>
          </div>
        </div>
      </div>
      <div class="esglist6">
        <div class="ztitle">
          ESG奖项
          <img src="../assets/images/zimg/img7-6.png" alt="" />
        </div>

        <div class="ul f-no-b-center">
          <div
            class="li f-no-s-center"
            v-for="(v, index) in awards_list"
            :key="index"
          >
            <div class="icon f-no-s-center">
              <img :src="v.photo" alt="" />
            </div>
            <div class="p" v-html="v.title"></div>
          </div>
          <div @click="change2" class="botbtn">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  props: ["cur"],
  data() {
    return {
      id: "",
      topbanner: {},
      trends_flag: [],
      trends_list: [],
      strategy: {},
      strategy_photo: [],
      manage_list: {},
      rate_list: [],
      awards_list: [],
      swiper: null,
      bannerSwiper: null,
      imageUrl: [],
      bannerPDF: null,
      pageNo: 1,
      total: 0,
      bannerEntitle: "",
      pageNo: 1,
      active: 0,
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
    this.id = this.$route.query.id;
    this.selected = this.$route.query.id;
    this.getData();
    //  this.initSwiper()
    this.getBanner();
    this.getAwardsList(this.pageNo);
  },
  watch: {
    // cur(val){
    //    this.id = val
    //    this.selected = val
    //    this.getData()
    // }
  },
  methods: {
    getData() {
      this.$fetch({
        url: "index.php/esg",
        type: "post",
        data: this.$qs.stringify({
          pageNo: this.pageNo,
        }),
      }).then((res) => {
        let _this = res.data;
        this.trends_flag = _this.trends_flag;
        this.trends_list = _this.trends_list;
        this.strategy = _this.strategy;
        this.strategy_photo = _this.strategy_photo;
        console.log(this.strategy);
        this.manage_list = _this.manage_list;
        this.rate_list = [...this.rate_list, ..._this.rate_list];
        this.$nextTick(() => {
          setTimeout(function () {
            this.swiper = new Swiper(".swiper-container2", {
              autoplay: 3000,
              slidesPerView: 3,
              slidesPerGroup: 3,
              // loop:true,
              pagination: ".pagination2",
            });
            this.bannerSwiper = new Swiper(".swiper-container1", {
              autoplay: 3000,
              slidesPerView: 1,
              pagination: ".pagination1",
              paginationClickable: true,
            });
          }, 500);
        });
      });
    },
    getBanner() {
      this.$fetch({
        url: "index.php/esg/banner",
        type: "post",
      }).then((res) => {
        this.imageUrl = res.data.banner_list;
        console.log(this.imageUrl);

        this.bannerPDF = res.data.banner_pdf.files;
        this.bannerEntitle = res.data.banner_pdf.entitle;
      });
    },
    tolink(id) {
      this.$router.push({
        path: "/esgview",
        query: { id: id },
      });
    },
    tolink2(id) {
      this.$router.push({
        path: "/strategyview",
        query: { id: id },
      });
    },

    initSwiper() {
      this.swiper = new Swiper(".swiper-container2", {
        autoplay: 3000,
        slidesPerView: 3,
        slidesPerGroup: 3,
        // loop:true,
        pagination: ".pagination2",
      });
      this.bannerSwiper = new Swiper(".swiper-container1", {
        autoplay: 3000,
        slidesPerView: 1,
        pagination: ".pagination1",
        paginationClickable: true,
      });
    },
    change2() {
      if (this.pageNo < this.total) {
        this.pageNo++;
        this.getAwardsList(this.pageNo);
      }
    },
    getAwardsList() {
      this.$fetch({
        url: "index.php/esg/awards_list/" + this.pageNo,
        type: "post",
        data: this.$qs.stringify({
          pageNo: this.pageNo,
        }),
      }).then((res) => {
        this.total = res.data.awards_list.length;
        this.awards_list = [...this.awards_list, ...res.data.awards_list];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.f-no-b-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.titleColor:hover {
  color: #396aff;
}
.f-no-s-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.tabsTop {
  left: 160px;
}
.f-no-c-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.f-no-col-c-center {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.f-no {
  display: flex;
  flex-flow: row wrap;
}

.nbanner {
  position: relative;
  overflow: hidden;
  img {
    //height: 560px;
    width: 100%;
  }
  a {
    z-index: 3;
    display: block;
    background-color: #396aff;
    width: 180px;
    color: #fff;
    height: 45px;
    font-size: 15px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    left: 45%;
    top: 53%;
  }
  h3 {
    position: absolute;
    left: 20%;
    font-size: 42px;
    top: 50%;
  }
  p {
    margin: 27px 0 60px 0;
    position: absolute;
    left: 20%;
    font-weight: normal;
    color: #c6c6c6;
    font-size: 18px;
    top: 58%;
  }
  .swiper-pagination {
    position: absolute;
    z-index: 1;
    bottom: 20px;
  }
}
.tabs {
  background: rgb(252, 253, 253);
  font-size: 14px;
  color: #333333;
  // position: sticky;
  // top: 70px;
  z-index: 5;
  ul {
    height: 70px;
    margin: 0 auto;
    li {
      margin-right: 60px;
      a {
        font-weight: 600;
        height: 60px;
        line-height: 60px;
        display: block;
        position: relative;
        padding: 0 20px;
      }
      
    }
    .on {
      color: #396aff;
    }
    a.on::after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: rgb(57, 106, 255);
      content: "";
    }
  }
}
.ztitle {
  position: relative;
  z-index: 3;
  color: #000;
  line-height: 1.2;
  font-size: 36px;
  text-align: center;
  img {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
.botbtn {
  width: 120px;
  height: 40px;
  border: 1px solid #396aff;
  color: #396aff;
  text-align: center;
  line-height: 36px;
  margin: 30px auto 0;
  cursor: pointer;
  transition: all 0.6s;
}
.botbtn:hover {
  background: #396aff;
  color: #fff;
}
.esglist2 {
  padding: 130px 0 80px;
  background: #fff;
  z-index: 1;
  .contentbox {
    width: 1200px;
    margin: 0 auto;
  }
  .ulbox {
    margin-top: 60px;
  }
  .titbox {
    padding: 50px 30px 40px;
    margin-bottom: -20px;

    .tit3 {
      color: #333;
      font-size: 18px;
      line-height: 1.5;
      font-weight: bold;
    }
    .tit4 {
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      height: 75px;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  .li {
    width: 380px;
    background: #f7f7f7;
    display: block;
    margin-right: 30px;
    cursor: pointer;
    .img {
      height: 250px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .time {
      font-size: 16px;
    }
  }
  .rightbox {
    width: 380px;
    background: #f7f7f7;
    padding: 0 20px;
    .li2 {
      padding: 20px 0;
      border-bottom: 1px dashed #c6c6c6;
      display: block;
      cursor: pointer;
    }
    .li2:last-child {
      border: none;
    }
    .tit4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 20px;
      color: #333;
    }
    .time {
      color: #666;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .morebtn {
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
    display: block;
    color: #396aff;
  }
}
.esglist3 {
  padding: 0 0 0;

  z-index: 1;
  .warpbox {
    padding: 130px 0;
    background: #f7f7f7;
  }
  .contentbox {
    width: 1200px;
    margin: 0 auto;
    .box {
      margin-top: 60px;
    }

    .text {
      width: 460px;
      font-size: 18px !important;
      color: #333;
      line-height: 1.6;
    }
    .img {
      width: 730px;
      margin-right: -96px;
      img {
        width: 100%;
      }
    }
  }
  .contentbox2 {
    .li.active {
      width: 41.66%;
      img {
        width: 41.66vw;
      }
      .p3 {
        display: block;
      }
    }
    .li {
      width: 18.75%;
      margin-right: 0.52%;
      overflow: hidden;
      position: relative;
      transition: all 0.6s;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 70%;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0)
        );
      }
      img {
        height: 560px;
        width: 41.66vw;
        object-fit: cover;
      }
      .txtbox {
        position: absolute;
        width: 100%;
        padding: 0 40px 80px;
        bottom: 0;
        left: 0;
        z-index: 3;
      }
      .tit2 {
        color: #fff;
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 15px;
      }
      .p3 {
        font-size: 20px;
        line-height: 1.8;
        color: #fff;
        display: none;
        transition: all 0.6s;
      }
    }
  }
}
.esglist4 {
  padding: 148px 0 120px;
  background: #fff;
  z-index: 1;
  .contentbox {
    width: 1200px;
    margin: 0 auto;
  }
  .img {
    margin-top: 80px;
    img {
      //width:100%;
      height: 74vh;
    }
  }
}

.esglist5 {
  padding: 148px 0 100px;
  background: #fafafa;
  z-index: 1;
  .swiper-pagination {
    position: relative;
    margin-top: 20px;
    color: #6688ee;
  }
  .contentbox {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    //justify-content: space-evenly;
  }
  .bg {
    width: 100%;
  }
  .icon {
    height: 100px;
    img {
      display: flex;
      width: 100%;
    }
  }
  .p {
    margin-top: 25px;
    text-align: center;
  }
  .ul {
    margin-top: 100px;
  }
  .li {
    width: 305px;
    position: relative;
  }
  .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    .p2 {
      font-size: 18px;
      line-height: 1.2;
      margin-top: 20px;
    }
  }
}
.esglist6 {
  padding: 140px 0 128px;
  background: #fff;
  z-index: 1;

  .ul {
    width: 1300px;
    margin: 60px auto 0;

    .li {
      padding: 20px 30px;
      width: 48.5%;
      height: 120px;
      margin-bottom: 3%;
      background: #f5f5f5;
    }
    .icon {
      width: 45%;
      padding-right: 5%;
      position: relative;
      img {
        width: 100%;
      }
      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50px;
        top: 50%;
        margin-top: -25px;
        right: 0;
        background: #dfdede;
      }
    }
    .p {
      padding-left: 5%;
      width: 55%;
      font-size: 16px;
      color: #000;
      line-height: 1.4;
    }
  }
}

@media (min-width: 1280px) {
  .tabs {
    background: rgb(252, 253, 253);
    font-size: 14px;
    color: #333333;
    z-index: 5;
    ul {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      li {
        margin-right: 60px;
      }
      .on {
        color: #396aff;
      }
    }
  }
}
</style>